import tw from 'twin.macro'
import { FiSun, FiMoon, FiLinkedin, FiDownload } from 'react-icons/fi'
import { Title, Subtitle } from 'components/Typography'
import { IconButton } from 'components/Button'
import { useTheme } from 'contexts/themeContext'

const Header = () => {
  const { theme, setTheme } = useTheme()

  const toggleTheme = () => {
    if (setTheme) {
      if (theme === 'dark') {
        setTheme('light')
      } else {
        setTheme('dark')
      }
    }
  }

  return (
    <div css={tw`mt-9 flex justify-between`}>
      <div css={tw`flex-col`}>
        <Title>ALAN MORTONI</Title>
        <Subtitle css={tw`text-gray-400`}>FRONT-END DEVELOPER</Subtitle>
      </div>
      <div></div>
      <div css={tw`space-x-4`}>
        <IconButton onClick={() => toggleTheme()} css={tw`bg-transparent hover:bg-transparent`}>
          {theme === 'dark' ? <FiSun size={22} /> : <FiMoon size={22} />}
        </IconButton>
        <IconButton
          as="a"
          href="https://www.linkedin.com/in/alanmortoni/?locale=en_US"
          target="_blank"
          css={tw`bg-transparent hover:bg-transparent`}
        >
          <FiLinkedin size={22} />
        </IconButton>
        <IconButton
          as="a"
          href="https://firebasestorage.googleapis.com/v0/b/resume-portifolio.appspot.com/o/23012023%20-%20Alan%20Mortoni%20-%20with%20cover%20letter.pdf?alt=media&token=d470f467-fd40-47ee-8957-bdcdc4cb1631"
          target="_blank"
          download
          css={tw`bg-transparent hover:bg-transparent`}
        >
          <FiDownload size={22} />
        </IconButton>
      </div>
    </div>
  )
}

export default Header
