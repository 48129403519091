import tw from 'twin.macro'
import { Education, Experience, Recommendation, TechStack as TechStackProps } from 'types/resume'
import { Header, ConnectedItems, Item, TechStack, Tech, Carousel } from 'features/resume'
import Typography from 'components/Typography'

type ResumeProps = {
  experiences: Experience[]
  education: Education[]
  recommendations: Recommendation[]
  techStack: TechStackProps[]
}

const Resume = ({ experiences, education, recommendations, techStack }: ResumeProps) => {
  return (
    <div css={tw`container mx-auto px-4`}>
      <Header />
      <ConnectedItems title="Experience">
        {experiences.map((experience) => (
          <Item
            company={experience.company}
            year={experience.year}
            title={experience.position}
            key={experience.id}
            summary={experience.summary}
          >
            <ul css={tw`list-disc`}>
              {experience.descriptions.map((description) => (
                <li css={tw`text-justify ml-4`} key={description}>
                  <Typography css={tw`text-opacity-75`}>{description}</Typography>
                </li>
              ))}
            </ul>
          </Item>
        ))}
      </ConnectedItems>

      <TechStack hidden={false} title="Technical Stack">
        {techStack.map((tech) => (
          <Tech key={tech.name} name={tech.name} percentage={tech.percentage} />
        ))}
      </TechStack>

      <ConnectedItems title="Education">
        {education.map((e) => (
          <Item institution={e.institution} year={e.year} title={e.title} key={e.id} summary={e.description}>
            <ul css={tw`list-disc`}>
              {e.points.map((item) => (
                <li css={tw`text-justify ml-4`} key={item}>
                  <Typography css={tw`text-opacity-75`}>{item}</Typography>
                </li>
              ))}
            </ul>
          </Item>
        ))}
      </ConnectedItems>

      {/* <Projects /> */}

      <div css={tw`pb-12`}>
        <Carousel recommendations={recommendations} />
      </div>
    </div>
  )
}

export default Resume
