import Typography from 'components/Typography'
import { ReactNode } from 'react'
import tw from 'twin.macro'
import { Experience, Education } from 'types/resume'

const Item = ({
  company,
  position,
  children,
  institution,
  year,
  title,
  summary,
}: Partial<Experience> &
  Partial<Education> & {
    children: ReactNode
  }) => {
  return (
    <div css={tw`flex flex-col md:flex-row`}>
      <div css={tw`flex flex-col w-60`} style={{ minWidth: 240 }}>
        <Typography css={tw`text-primary`}>{company || institution}</Typography>
        <Typography css={tw`text-secondary`}>{year}</Typography>
      </div>

      <div css={tw`flex flex-col mb-6`}>
        <Typography css={tw`text-primary`}>{position || title}</Typography>
        <div>
          <Typography css={tw`my-4 text-justify`}>{summary}</Typography>
        </div>
        <div css={tw`ml-6 md:ml-8`}>{children}</div>
      </div>
    </div>
  )
}

export default Item
