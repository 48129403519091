import Typography from 'components/Typography'
import tw, { styled } from 'twin.macro'

const Container = styled.div(() => [tw`border-gray-400 border-dashed h-1.5 w-full rounded relative`])
const Bar = styled.div(() => [tw`absolute bg-brand h-1.5 rounded-tl rounded-bl`])

const Tech = ({ name, percentage }: { name: string; percentage: number }) => {
  return (
    <div css={tw`flex items-center ml-4`}>
      <div css={tw`w-1/3`}>
        <Typography>{name}</Typography>
      </div>

      <Container style={{ borderWidth: 1 }}>
        <Bar style={{ width: `${percentage}%`, top: -1, left: -1 }} />
      </Container>
    </div>
  )
}

export default Tech
