import tw from 'twin.macro'
import Resume from 'pages/resume'
import { experiences, education, recommendations, techStack } from 'data/resume'

function App() {
  return (
    <div css={tw`h-full`}>
      <Resume experiences={experiences} education={education} recommendations={recommendations} techStack={techStack} />
    </div>
  )
}

export default App
