import { PrimaryTextButton } from 'components/Button'
import { useState, Dispatch, SetStateAction } from 'react'
import tw, { styled } from 'twin.macro'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import Typography, { Title } from 'components/Typography'
import { Recommendation } from 'types/resume'
import SectionTitle from '../Title'

const Card = styled.div(() => [
  tw`select-none`,
  tw`bg-primary rounded p-5 h-full shadow`,
  tw`flex flex-col justify-between`,
])
const CustomButtom = styled(PrimaryTextButton)(() => [
  tw`focus:outline-none focus:ring focus:border-primary p-2 rounded-3xl border-primary border-2`,
])
const Pagination = ({
  selected,
  total,
  onChange,
}: {
  selected: number
  total: number
  onChange: Dispatch<SetStateAction<number>>
}) => {
  const handleIncrement = () => {
    if (selected + 1 < total) {
      onChange(selected + 1)
    } else {
      onChange(0)
    }
  }
  const handleDecrement = () => {
    if (selected > 0) {
      onChange(selected - 1)
    } else {
      onChange(total - 1)
    }
  }

  return (
    <div css={tw`flex mt-4`}>
      <div css={tw` flex flex-1 justify-center`}>
        <CustomButtom css={tw`mr-4`} onClick={handleDecrement}>
          <FaChevronLeft />
        </CustomButtom>

        <CustomButtom onClick={handleIncrement}>
          <FaChevronRight />
        </CustomButtom>
      </div>

      <div css={tw`w-9`}>
        <Typography css={tw`text-gray-400`}>
          {selected + 1}/{total}
        </Typography>
      </div>
    </div>
  )
}

const RecommendationCarrousel = ({ recommendations }: { recommendations: Recommendation[] }) => {
  const [selected, setSelected] = useState(0)

  return (
    <div>
      <div css={tw`flex items-center`}>
        <SectionTitle>Recommendations from LinkedIn</SectionTitle>
      </div>

      {recommendations.map((rec, index) => {
        if (selected === index) {
          return (
            <>
              <Card key={rec.name} css={tw`h-52`}>
                <div css={tw`flex mb-4`}>
                  <Title css={tw`mr-4`}>{'"'}</Title>
                  <Typography>{rec.recommendation}</Typography>
                </div>
                <div css={tw`flex flex-col items-end`}>
                  <Typography>{rec.name}</Typography>
                  <Typography css={tw`text-gray-400`}>{rec.position}</Typography>
                </div>
              </Card>
              <Pagination selected={selected} total={recommendations.length} onChange={setSelected} />
            </>
          )
        }
        return null
      })}
    </div>
  )
}

export default RecommendationCarrousel
