import tw, { styled } from 'twin.macro'
import { ReactNode, useState, useEffect, createRef } from 'react'
import Title from '../Title'

type ConnectedItemsProps = {
  children: ReactNode[]
  hidden?: boolean
  title: string
}

const Trace = styled.div(() => [tw`absolute`, tw`border-primary`, tw`ml-1.5 mt-2.5`])
const Dot = styled.div(() => [tw`relative h-3.5 w-3.5 mr-4 mt-1 bg-primary`, tw`border-2 border-primary rounded-xl`])

const ConnectedItems = ({ children, hidden = false, title }: ConnectedItemsProps) => {
  const [height, setHeight] = useState(0)
  const elementsRef = children.map(() => createRef<HTMLDivElement>())

  useEffect(() => {
    const h = elementsRef.slice(0, -1).reduce((acc, ref) => {
      if (ref?.current?.clientHeight) {
        return acc + ref.current.clientHeight
      } else {
        return acc + 0
      }
    }, 0)

    setHeight(h)
  }, [elementsRef])

  return (
    <div css={[tw`mb-4`, hidden && tw`hidden`]}>
      <Title>{title}</Title>
      <div css={tw`flex flex-col`}>
        <Trace style={{ height, borderWidth: 1 }} />

        {children.map((child, i) => (
          <div css={tw`flex`} key={i} ref={elementsRef[i]}>
            <Dot style={{ minWidth: 14 }} />
            {child}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ConnectedItems
