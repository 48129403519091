import { Title } from 'components/Typography'
import SectionTitle from '../Title'
import Card from 'components/Card'
import tw from 'twin.macro'
import { GiBurningMeteor, GiChessKnight } from 'react-icons/gi'
import { IoConstructOutline } from 'react-icons/io5'

const Projects = () => {
  return (
    <div>
      <SectionTitle>Projects</SectionTitle>

      <div css={tw`grid grid-cols-1 sm:grid-cols-3 gap-4`}>
        <Card>
          <GiBurningMeteor size={56} />
          <Title>QVSR</Title>
        </Card>

        <Card>
          <GiChessKnight size={56} />
          <Title>My chess career</Title>
        </Card>

        <Card>
          <IoConstructOutline size={56} />
        </Card>
      </div>
    </div>
  )
}

export default Projects
