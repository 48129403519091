import tw from 'twin.macro'
import Typography from 'components/Typography'

const Title = ({ children }: { children: string }) => {
  return (
    <div css={[tw`inline-block`, tw`border-dashed border-b-2 border-primary mt-2 mb-4`]}>
      <Typography css={tw`text-gray-400 uppercase`}>{children}</Typography>
    </div>
  )
}

export default Title
