import tw, { styled } from 'twin.macro'

const Card = styled.div(() => [
  tw`select-none`,
  tw`bg-primary border rounded-md p-5 h-full`,
  tw`shadow-card transition-shadow duration-150`,
  tw`flex flex-col justify-center items-center`,
])

export default Card
