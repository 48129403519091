import { ReactNode } from 'react'
import tw from 'twin.macro'
import Title from '../Title'

const TechStack = ({ children, hidden = false, title }: { children: ReactNode; hidden: boolean; title: string }) => {
  return (
    <div css={[tw`mb-4`, hidden && tw`hidden`]}>
      <Title>{title}</Title>
      <div css={[tw`grid grid-cols-1 sm:grid-cols-2 gap-2.5`]}>{children}</div>
    </div>
  )
}

export default TechStack
